import React from 'react'
import { Input as StyleInput, InputProps } from '@chakra-ui/react'

export const Input = (props: InputProps) => {
  return (
    <StyleInput
      size='lg'
      borderRadius='4px'
      bgColor='white'
      focusBorderColor='blue.500'
      _invalid={{
        backgroundColor: 'red.50',
        borderColor: 'red.400',
        borderWidth: '2px',
        borderStyle: 'solid'
      }}
      {...props}
    >
      {props.children}
    </StyleInput>
  )
}
