import React, { useEffect } from 'react'
import { Flex, Text, Box } from '@chakra-ui/react'
import { PercentageSlider } from './PercentageSlider'
import { useFormikContext } from 'formik'
import { CurrencyInputControl } from './CurrencyInputControl'

export enum CalcQuestionEnum {
  LIFE = 'life',
  TRAUMA = 'trauma',
  TPD = 'tpd'
}

interface FormValues {
  dependents: Array<{
    traumaCapital: number
    lifeCapital: number
    tpdCapital: number
  }>
  [key: string]: number | Array<{ [key: string]: number }> | undefined
}

export function EducationCostsFormSlider({
  amount,
  questions,
  formName,
  parentIndex,
  hideSliderPercentDisplay,
  name,
  hasPartner,
}: {
  amount: number
  questions: { key: string; value: string }[]
  formName: string
  name?: string
  parentIndex?: number
  hideSliderPercentDisplay?: boolean
  hasPartner?: boolean
}) {
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const [sliderValues, setSliderValues] = React.useState<
    Record<string, number>
  >({})

  useEffect(() => {
    // Update slider values when the capital values change
    questions.forEach((question) => {
      const capital = Number(values[`${question.key}Capital`])

      if (capital) {
        const sliderValue = (capital / amount) * 100
        setSliderValues((prev) => ({ ...prev, [question.key]: sliderValue }))
      }
    })
    if (typeof parentIndex === 'number') {
      const traumaCapital = values.dependents[parentIndex].traumaCapital
      const lifeCapital = values.dependents[parentIndex].lifeCapital
      const tpdCapital = values.dependents[parentIndex].tpdCapital
      // Update slider values when the capital values change
      if (traumaCapital) {
        const sliderValue = (traumaCapital / amount) * 100
        setSliderValues((prev) => ({ ...prev, trauma: sliderValue }))
      }
      if (lifeCapital) {
        const sliderValue = (lifeCapital / amount) * 100
        setSliderValues((prev) => ({ ...prev, life: sliderValue }))
      }
      if (tpdCapital) {
        const sliderValue = (tpdCapital / amount) * 100
        setSliderValues((prev) => ({ ...prev, tpd: sliderValue }))
      }
    }
  }, [values.traumaCapital, values.lifeCapital, values.tpdCapital])

  useEffect(() => {
    // Update capital values based on the amount and form specifics when `amount` changes.
    questions.forEach((question) => {
      const selectedYears = Number(
        values[`${formName}${question.key}Capital${name}`]
      )
      const calculatedCapital = Math.floor(selectedYears * amount)
      setFieldValue(`${question.key}Capital${name}`, calculatedCapital)
    })
  }, [amount])

  // Updates slider values when amount changes
  useEffect(() => {
    const newSliderValues = Object.entries(sliderValues).reduce(
      (acc, [questionKey, sliderValue]) => {
        const newCapital = Math.floor(((sliderValue || 0) / 100) * amount)

      
          setFieldValue(
            `${name}.${parentIndex}.${questionKey}Capital`,
            newCapital
          )
      
        return { ...acc, [questionKey]: sliderValue }
      },
      {}
    )
    setSliderValues(newSliderValues)
  }, [amount, setFieldValue])


  // When slider changes updates the capital value
const handleSliderChange = (value: number, questionKey: string) => {
  const adjustedValue = value > 100 ? 100 : value
  setSliderValues((prev) => ({ ...prev, [questionKey]: adjustedValue }))
  const newCurrencyValue = Math.floor((value / 100) * amount)
    setFieldValue(
      `${name}.${parentIndex}.${questionKey}ClientCapital`,
       newCurrencyValue
    )
    if (hasPartner) {
      setFieldValue(
        `${name}.${parentIndex}.${questionKey}PartnerCapital`,
        newCurrencyValue
      )
  }
}

  return (
    <>
      {questions.map((question, index) => {
        const flexProps = { align: 'center', justify: 'space-between' }
        return (
          <Box key={index}>
            <Text>{question.value}</Text>
            <Flex key={index} mb={4} {...flexProps}>
              <Box mt={8} ml={1} width='50%'>
                <PercentageSlider
                  sliderValue={sliderValues[question.key] || 0}
                  setSliderValue={(value) =>
                    handleSliderChange(value, question.key)
                  }
                  hideSliderPercentDisplay={hideSliderPercentDisplay}
                />
              </Box>
              <Box width='40%'>
                <Flex
                  direction='row'
                  align='flex-start'
                  justify='flex-start'
                  gap={5}
                >
                  <Box>
                    {hasPartner && <Text mb={4}>Client</Text>}
                    <CurrencyInputControl
                      name={`${name}.${parentIndex}.${question.key}ClientCapital`}
                      variant='filled'
                      fontFamily={'thin'}
                      placeholder='$0'
                      resize='none'
                      borderWidth='1px'
                      ml={4}
                      defaultValue={0}
                      isRequired={false}
                      // Updates the slider % when the input value changes
                      onChange={(value) => {
                        const newInputValueInCents = Number(value) * 100
                        const newSliderValue =
                          (newInputValueInCents / amount) * 100
                        setSliderValues((prev) => ({
                          ...prev,
                          [question.key]: newSliderValue
                        }))
                      }}
                    />
                  </Box>
                  {hasPartner && (
                    <Box>
                      <Text mb={4}>Partner</Text>
                      <CurrencyInputControl
                        name={`${name}.${parentIndex}.${question.key}PartnerCapital`}
                        variant='filled'
                        fontFamily={'thin'}
                        placeholder='$0'
                        resize='none'
                        borderWidth='1px'
                        ml={4}
                        numberInputProps={{
                          defaultValue: 0
                        }}
                        isRequired={false}
                        // Updates the slider % when the input value changes
                        onChange={(value) => {
                          const newInputValueInCents = Number(value) * 100
                          const newSliderValue =
                            (newInputValueInCents / amount) * 100
                          setSliderValues((prev) => ({
                            ...prev,
                            [question.key]: newSliderValue
                          }))
                        }}
                      />
                    </Box>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Box>
        )
      })}
    </>
  )
}
