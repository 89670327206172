import React from 'react'
import { Box, Heading, Center, Flex, Spacer } from '@chakra-ui/react'
interface Props {
  children?: React.ReactNode | null
  startComponent?: React.ReactNode | null
  endComponent?: React.ReactNode
}
const Header: React.FC<Props> = ({
  children,
  startComponent,
  endComponent
}) => {
  const StartComponent = startComponent ? (
    <Box>
      <Center h='100%'>{startComponent}</Center>
    </Box>
  ) : null
  const EndComponent = endComponent ? (
    <Box>
      <Center h='100%'>{endComponent}</Center>
    </Box>
  ) : null

  const marginRight = startComponent ? 5 : 0
  return (
    <React.Fragment>
      <Flex
        bg='blue.800'
        maxWidth='2xl'
        w='100%'
        marginX='auto'
        zIndex={200}
        position='fixed'
        color='white'
        px='6'
        height='14'
        justifyContent='start'
      >
        {StartComponent}
        <Box w='100%'>
          <Center h='100%' px={2} mr={marginRight}>
            {/* marginRight for hamburger icon width = 5 */}
            {/* might need to re look at this as its slighty offset by 10% */}
            <Heading
              fontSize={{ base: 'md', md: 'lg' }}
              isTruncated
              letterSpacing={-0.5}
              lineHeight='1'
            >
              {children}
            </Heading>
          </Center>
        </Box>
        {EndComponent}
      </Flex>
      {/* Pushes content down because the header is position fixed */}
      <Box height='14' />
    </React.Fragment>
  )
}
// nop
export default Header
