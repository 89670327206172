import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

export const MonoText: React.FC<TextProps> = (props) => {
  return (
    <Text fontFamily='mono' {...props}>
      {props.children}
    </Text>
  )
}
export default MonoText
