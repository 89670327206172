import React, { useRef, useEffect } from 'react'
import { Box, BoxProps, Flex, VStack } from '@chakra-ui/react'

type ParallaxHeaderProps = BoxProps & {
  headerBackground: string
  header: any
  children: any
  backgroundProps?: BoxProps
}

const ParallaxHeader = ({
  headerBackground,
  header,
  children,
  backgroundProps,
  ...props
}: ParallaxHeaderProps) => {
  return (
    <Box display='flex' position='relative' flex={1} alignItems='stretch'>
      <VStack
        width='100%'
        height={[44, 44, 64]}
        position='fixed'
        marginX='auto'
        maxWidth='2xl'
        alignItems='center'
        justifyContent='center'
        color='white'
        paddingBottom='11px'
        textShadow='0px 0px 16px black'
        overflow='hidden'
      >
        <HeaderBg image={headerBackground} {...backgroundProps} />
        {header}
      </VStack>
      <Flex
        flex={1}
        flexDirection='column'
        alignItems='stretch'
        background='white'
        borderTopLeftRadius={11}
        borderTopRightRadius={11}
        marginTop={[44, 44, 64]}
        p='6'
        zIndex={1}
        transform='translateY(-11px)'
        {...props}
      >
        {children}
      </Flex>
    </Box>
  )
}

const HeaderBg = ({ image, ...props }: { image: string }) => {
  const boxRef = useRef<any>(null)

  useEffect(() => {
    const func = () => {
      if (!boxRef.current) return
      const y = window.scrollY / 5
      const scale = 1 + window.scrollY / 200
      const tx = `translate3d(-50%, -${y}%, 0) scale(${scale})`
      boxRef.current.style.transform = tx
    }
    document.addEventListener('scroll', func)
    return () => document.removeEventListener('scroll', func)
  }, [])

  return (
    <Box
      ref={boxRef}
      position='absolute'
      zIndex='-1'
      top='0'
      left='50%'
      width='100%'
      height={[44, 44, 64]}
      transform='translateX(-50%)'
      backgroundPosition='center 20%'
      backgroundSize='cover'
      backgroundImage={`linear-gradient(to right, #0002, #0002), linear-gradient(to right, #00466faa, #00466faa), url('${image}')`}
      backgroundBlendMode='normal, soft-light, normal'
      {...props}
    />
  )
}

export default ParallaxHeader
