type routes = {
  [key: string]: string
}

const externalRoutes: routes = {
  facebook: 'https://www.facebook.com/lifetimeadviceforlife/',
  linkedin: 'https://www.linkedin.com/company/lifetime-group-ltd-/',
  youtube: 'https://www.youtube.com/channel/UCh2mkrM_wHUILb2-9isODmg',
  termsOfUse: 'https://www.lifetime.co.nz/mylifetime/terms-of-use/',
  privacyPolicy: 'https://www.lifetime.co.nz/mylifetime/privacy-policy/',
  investmentMoreInformation:
    'https://www.lifetime.co.nz/personal-advice/investments/getting-started/',
}

const routes: routes = {
  dashboard: '/dashboard',
  goals: '/goals',
  goalsAdd: '/goals/add',
  goalsEdit: '/goals/edit',
  assets: '/assets',
  assetsAdd: '/assets/add',
  assetsEdit: '/assets/edit',
  debts: '/debts',
  debtsAdd: '/debts/add',
  debtsEdit: '/debts/edit',
  netwealth: '/netwealth',
  insurance: '/insurance',
  contactUs: '/contact-us',
  investment: '/investment',
  investmentReport: '/investment/report',
  landing: '/landing',
  welcome: '/welcome',
  register: '/register',
  polices: '/polices',
  profile: '/profile',
  callback: '/callback',
  login: '/login',
  loginPending: '/login/pending',
  logout: '/logout',
  income: '/income',
  incomeAdd: '/income/add',
  incomeEdit: '/income/edit',
  ...externalRoutes,
}

export default routes

export const getPageFromUrl = (url: string) => {
  const page = Object.keys(routes).find((key) => routes[key] === url)
  if (page) {
    return page.charAt(0).toUpperCase() + page.slice(1)
  }
  return 'Unknown'
}
