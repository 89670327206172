import React, { FC } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  VStack,
  InputProps,
  HTMLChakraProps
} from '@packages/ui-components'
import { ErrorMessage, FieldArray, useField } from 'formik'
import { BaseProps } from './base-props'
import InputControl from './InputControl'

export type MultiInputControlProps = BaseProps & {
  inputProps?: InputProps
  labelStyle?: HTMLChakraProps<'label'>
  Tooltip?: React.ElementType
}

type XplanClientAccount = {
  id: string
}

const Empty = { xplanClientId: '' }

export const MultiInputControl: FC<MultiInputControlProps> = (
  props: MultiInputControlProps
) => {
  const { name, label, children, ...rest } = props

  const [field, meta] = useField(name)

  const array = field.value as XplanClientAccount[]

  return (
    <FormControl id={name} name={name} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <DependantsInner
            name={name}
            arrayHelpers={arrayHelpers}
            array={array}
          />
        )}
      />
      <ErrorMessage name={props.name}>
        {(errors) =>
          typeof errors === 'string' ? (
            <FormErrorMessage>{errors}</FormErrorMessage>
          ) : null
        }
      </ErrorMessage>
    </FormControl>
  )
}

const DependantsInner = ({
  name,
  arrayHelpers,
  array
}: {
  name: string
  arrayHelpers: any
  array: XplanClientAccount[]
}) => {
  return (
    <div>
      {array && array.length > 0 ? (
        <>
          {array.map((_, index) => (
            <VStack key={index} bg="gray.300" p="4" mb="4" borderRadius="lg">
              <HStack w="full" alignItems="flex-start">
                <InputControl
                  name={`${name}.${index}.xplanClientId`}
                  label="Xplan ID"
                />
              </HStack>
            </VStack>
          ))}

          <Button
            size="sm"
            variant="ghost"
            onClick={() => arrayHelpers.remove(array.length - 1)}
          >
            Remove one
          </Button>

          <Button
            size="sm"
            variant="ghost"
            onClick={() => arrayHelpers.push(Empty)}
          >
            Add another xPlan Client ID
          </Button>
        </>
      ) : (
        <Button
          size="sm"
          variant="ghost"
          onClick={() => arrayHelpers.push(Empty)}
        >
          Add xPlan Client IDs
        </Button>
      )}
    </div>
  )
}
