import React, { ReactNode } from 'react'
import {  Box, ChakraProvider, extendTheme } from '@chakra-ui/react'

export type UnderLinedTabProps = {
  children?: ReactNode
}

const customTheme = extendTheme({
  components: {
    Tabs: {
      variants: {
        enclosedThickBottom: () => ({
          tab: {
            borderColor: 'inherit',
            bg: '#E2E7EE',
            padding: '1rem 2rem',
            marginRight: '0.88rem',
            borderTopLeftRadius: 'md',
            borderTopRightRadius: 'md',
            color: '#000',
            fontWeight: 'bold',
            fontSize: '1.25rem',
            _selected: {
              bg: 'white',
              borderBottom: '5px solid',
              borderColor: '#2B6CB0',
              color: '#2B6CB0',
              fontWeight: 'bold',
              fontSize: '1.25rem',
              boxShadow: 'none'
            }
          },
          tablist: {
            borderBottom: '1px solid',
            borderColor: 'gray.200'
          },
          tabpanel: {
            borderWidth: '1px',
            borderColor: 'gray.200',
            borderTop: 'none',
            bg: 'white'
          }
        })
      }
    }
  }
})

const UnderLinedTab = ({ children }: UnderLinedTabProps) => {
  return (
    <Box marginTop={5}>
      <ChakraProvider theme={customTheme}>
        {children}
        </ChakraProvider>
    </Box>
  )
}
export default UnderLinedTab
