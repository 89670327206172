import React from 'react'
import {
  Button as StyleButton,
  ButtonProps,
  ResponsiveValue
} from '@chakra-ui/react'
import * as CSS from 'csstype'

export type ExtraButtonProps = {
  textTransform?: ResponsiveValue<CSS.Property.TextTransform>
}

export const Button: React.FC<ButtonProps & ExtraButtonProps> =
  React.forwardRef((props, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <StyleButton
        ref={ref}
        background={props.background ? props.background : 'blue.800'}
        textTransform={props.textTransform ? props.textTransform : 'uppercase'}
        size='lg'
        fontSize='14px'
        borderRadius='4px'
        {...props}
      >
        {props.children}
      </StyleButton>
    )
  })
