import React from 'react'
import {
  Center,
  Spinner,
  VStack,
  Image,
  Spacer,
  ImageProps
} from '@chakra-ui/react'

export const LoadingSpinner = ({
  image,
  imageProps
}: {
  image?: string
  imageProps?: ImageProps
}) => {
  return (
    <Center flex={1}>
      <VStack spacing='10'>
        {image ? (
          <Image src={image} alt='Logo' height='16' {...imageProps} />
        ) : null}
        <Spinner color='blue.800' />
      </VStack>
    </Center>
  )
}
export default LoadingSpinner
