import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Stack,
  FormErrorMessage,
  Tooltip,
  Flex
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React, { FC, ReactNode } from 'react'
import { BaseProps } from './base-props'
import { InfoOutlineIcon } from '@chakra-ui/icons'

export type CheckboxSingleProps = BaseProps & {
  checkboxProps?: CheckboxProps
  children?: ReactNode
  checkboxOptions?: { key: unknown; label: string }[]
  direction?: 'row' | 'column'
  spacing?: number | string
  hasTooltip?: boolean
  tooltipLabels?: { key: unknown; label: string }[]
}

// See https://github.com/chakra-ui/chakra-ui/discussions/3262

export const MultiCheckboxControl: FC<CheckboxSingleProps> = (
  props: CheckboxSingleProps
) => {
  const {
    name,
    label,
    children,
    checkboxOptions,
    checkboxProps,
    direction = 'column',
    spacing,
    hasTooltip = false,
    tooltipLabels,
    ...rest
  } = props
  const [field, meta] = useField(name)
  const { onChange, ...restField } = field

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <CheckboxGroup {...restField}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Stack direction={direction} spacing={spacing}>
          {checkboxOptions &&
            checkboxOptions.map((opt) => {
              const tooltipLabel = tooltipLabels?.find(
                (t) => t.key === opt.key
              )?.label
              return (
                <Checkbox
                  key={String(opt.key)}
                  name={name}
                  value={String(opt.key)}
                  onChange={onChange}
                  {...checkboxProps}
                >
                  {hasTooltip && tooltipLabel ? (
                    <Flex alignItems="center" gap={2}>
                      {opt.label}
                      <Tooltip label={tooltipLabel} placement="bottom-end">
                        <InfoOutlineIcon />
                      </Tooltip>
                    </Flex>
                  ) : (
                    <>{opt.label}</>
                  )}
                </Checkbox>
              )
            })}
          {children}
        </Stack>
        <ErrorMessage component={FormErrorMessage} name={name} />
      </CheckboxGroup>
    </FormControl>
  )
}
