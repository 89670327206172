import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  HTMLChakraProps
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React, { FC, ReactNode } from 'react'
import { isFalsy } from 'utility-types'
import { BaseProps } from './base-props'

export type CheckboxSingleProps = BaseProps & {
  checkboxProps?: CheckboxProps
  children?: ReactNode
  checkboxOptions?: { key: unknown; label: string }[]
}

const isCheckboxChecked = (value: string | Array<string>): boolean => {
  return !isFalsy(value) && value.length !== 0
}

export const CheckboxControl: FC<CheckboxSingleProps> = (
  props: CheckboxSingleProps
) => {
  const { name, label, children, checkboxOptions, checkboxProps, ...rest } =
    props
  const [field, meta] = useField(name)
  const { onChange, ...restField } = field

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <Checkbox
        defaultChecked={isCheckboxChecked(field.value)}
        key={String(name)}
        name={name}
        value={String(label)}
        onChange={onChange}
        {...checkboxProps}
      >
        {label}
      </Checkbox>
      <ErrorMessage component={FormErrorMessage} name={name} />
    </FormControl>
  )
}
