import { Flex, Spacer, useAccordionItemState } from '@chakra-ui/react'
import { ReactNode } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from '@packages/ui-components'

import React, {
  PropsWithChildren,
  useEffect,
} from 'react'
import CircleWithText from './CircleWithText';


/**
 * AccordionTrigger
 * 
 * This allows us to handle opening and closing the accordion item if there's an error
 * We need to pass error as an object so we can see the prop change (booleans only fire once here)
 * @param param0 
 */
export function AccordionTrigger({
  children,
  error,
}: PropsWithChildren<{
  children: ReactNode
  error?: { errorStatus?: boolean }
  counter?: string
  mt?: string
  panelVariant?: boolean
}>) {
  const { onOpen } = useAccordionItemState()
  useEffect(() => {
    if (error?.errorStatus) {
      onOpen()
    }
  }, [error])

  return <>{children}</>
}

export default function AccordionSection({
  children,
  accordionTitle,
  error,
  counter,
  mt = '8px',
  panelVariant = false
}: PropsWithChildren<{
  accordionTitle: string
  error?: object
  counter?: string
  mt?: string
  panelVariant?: boolean
}>) {
  return !panelVariant ? (
    <AccordionItem>
      <AccordionTrigger error={error}>
        <AccordionButton>
          <Box flex='1'>
            <Text textAlign='left' textTransform='none'>
              {accordionTitle}
              {counter && <CircleWithText innerText={counter} />}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel mt={mt}>{children}</AccordionPanel>
      </AccordionTrigger>
    </AccordionItem>
  ) : (
    <AccordionItem border='none' paddingX='8px'>
      <AccordionTrigger error={error}>
        <AccordionButton>
          <Flex
            direction='row'
            borderBottom='1px'
            borderBottomColor='gray.200'
            width='full'
            paddingBottom='2'
            alignContent='center'
            alignItems="center"
          >
              <Text textAlign='left' fontWeight='bold' fontSize="lg">
                {accordionTitle}
              </Text>
              {counter && <CircleWithText innerText={counter} />}
            <Spacer />
            <AccordionIcon />
          </Flex>
        </AccordionButton>
        <AccordionPanel mt={mt}>{children}</AccordionPanel>
      </AccordionTrigger>
    </AccordionItem>
  )
}