import React from 'react'
import {
  DrawerCloseButton as OffCanvasCloseButton,
  CloseButtonProps
} from '@chakra-ui/react'

export const DrawerCloseButton = (props: CloseButtonProps) => {
  return (
    <OffCanvasCloseButton
      fontSize='20px'
      position='relative'
      top='0'
      right='0'
      {...props}
    />
  )
}
