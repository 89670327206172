import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip
} from '@chakra-ui/react'
import React from 'react'

interface PercentageSliderProps {
  sliderValue: number
  setSliderValue: (value: number) => void
  formName?: string
  /* Field label for Aria purposes */
  label?: string
  hideSliderPercentDisplay?: boolean
}

export const PercentageSlider: React.FC<PercentageSliderProps> = ({
  sliderValue,
  setSliderValue,
  hideSliderPercentDisplay,
  label
}) => {
  // Handle the tooltip value
  // we want this to change when the slider is being dragged
  // The default value is the slider value itself - this needs to get passed in from the parent as we don't know what this value might be
  const [tooltipValue, setTooltipValue] = React.useState<number>(
    sliderValue ?? 0
  )

  return (
    <Slider
      defaultValue={sliderValue ?? 0}
      min={0}
      max={100}
      // Set the tooltip value when the slider is being dragged
      onChange={setTooltipValue}
      // Set the callback value when the slider is released
      // If we don't do this, this will cause all parent components to re-render A LOT
      // in-turn causing the slider to be janky and major performance issues
      onChangeEnd={setSliderValue}
      focusThumbOnChange={false}
      aria-label={label}
    >
      <SliderTrack bg={'blue.400'}>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg='blue.500'
        color='white'
        placement='top'
        isOpen={hideSliderPercentDisplay ? !hideSliderPercentDisplay : true}
        label={`${Math.round(tooltipValue)}%`}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  )
}
