import Dinero from 'dinero.js'

// Formats price as $20
export const formatPrice = (amount: number | string) => {
  amount = (typeof amount === 'string' ? parseInt(amount) : amount) || 0
  return Dinero({ amount: amount }).toFormat('$0,0')
}

// Formats price as $20.00
export const formatPrice2DP = (amount: number) =>
  Dinero({ amount: amount }).toFormat('$0,0.00')

export const fromLocalized = (str: string) =>
  parseInt(str.replace(',', '.').replace(/^\$/, '')) * 100
