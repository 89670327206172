import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HTMLChakraProps,
  Input,
  InputProps
} from '@packages/ui-components'
import { ErrorMessage, useField, useFormikContext } from 'formik'

import { BaseProps } from './base-props'

export type InputControlProps = BaseProps & {
  inputProps?: InputProps
  type?: string
  labelStyle?: HTMLChakraProps<'label'>
  Tooltip?: React.ElementType
  inputSize?: 'sm' | 'md' | 'lg'
  value?: string | number | readonly string[]
}

const InputControl: React.FC<InputControlProps> = (
  props: InputControlProps
) => {
  const { name, inputProps, labelStyle, Tooltip, inputSize } = props

  const [field, meta] = useField(name)
  const { onChange, value, ...restField } = field
  const formik = useFormikContext()

  return (
    <FormControl
      id={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
    >
      {Tooltip ? (
        <Tooltip>
          <FormLabel htmlFor={props.name} {...labelStyle}>
            {props.label}
          </FormLabel>
        </Tooltip>
      ) : (
        props.label && (
          <FormLabel htmlFor={props.name} {...labelStyle}>
            {props.label}
          </FormLabel>
        )
      )}
      <Input
        {...field}
        bg="white"
        size={inputSize || 'lg'}
        {...inputProps}
        value={value || ''}
        {...props}
        type={props.type}
      />
      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </FormControl>
  )
}
export default InputControl
