import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputProps
} from '@packages/ui-components'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { BaseProps } from './base-props'
import formatDate from 'date-fns/format'

export type InputControlProps = BaseProps & {
  inputProps?: InputProps
}

const renderCustomHeader: ReactDatePickerProps['renderCustomHeader'] = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  decreaseYear,
  increaseYear
}) => (
  <>
    <HStack margin={1} justifyContent="center" alignItems="center">
      <Button
        padding="8px"
        fontSize="11px"
        background="blue.400"
        color="blue.800"
        height="auto"
        marginRight="2px"
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
      >
        - Year
      </Button>

      <Box flex={1} marginX="8px" textAlign="center" />
      <Button
        padding="8px"
        fontSize="11px"
        background="blue.400"
        color="blue.800"
        height="auto"
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
      >
        Year +
      </Button>
    </HStack>
    <HStack spacing={0} margin={1} justifyContent="center" alignItems="center">
      <Button
        padding="10px"
        background="blue.400"
        color="blue.800"
        height="auto"
        marginRight="2px"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        {'<'}
      </Button>

      <Box flex={1} marginX="8px" textAlign="center">
        {formatDate(date, 'dd/MM/yyyy')}
      </Box>

      <Button
        padding="10px"
        background="blue.400"
        color="blue.800"
        height="auto"
        marginLeft={0}
        marginRight="2px"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        {'>'}
      </Button>
    </HStack>
  </>
)

const CustomInput = React.forwardRef<any, any>((props) => {
  return (
    <Input
      {...props}
      borderColor="gray.500"
      errorBorderColor="red"
      isInvalid={props.isInvalid}
    />
  )
})

export const DatePickerControl = (props: InputControlProps) => {
  const { name, label } = props

  const [field, meta, { setValue }] = useField(name)
  const formik = useFormikContext()

  const setDate = (date: Date) => {
    // Avoid mutating the original date object by creating a new date and adjust the date to UTC and setting the hours based on the timezone offset
    const utcDate =
      date && new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    // Convert to ISO string format - to ensure the date is stored in UTC
    const formattedDate = utcDate && utcDate.toISOString()
    setValue(formattedDate)
  }

  const invalid = !!meta.error && !!meta.touched

  return (
    <FormControl
      id={props.name}
      isInvalid={invalid}
      isRequired={props.isRequired}
    >
      {props.label && <FormLabel htmlFor={props.name}>{label}</FormLabel>}
      <DatePicker
        onFocus={() => formik.setFieldTouched(field.name, true, true)}
        id={props.name}
        selected={
          typeof field.value === 'string' && field.value !== ''
            ? new Date(field.value)
            : field.value
        }
        onChange={setDate}
        showPopperArrow
        placeholderText={props.placeholder}
        renderCustomHeader={renderCustomHeader}
        popperPlacement="top-start"
        popperModifiers={{
          preventOverflow: {
            enabled: true
          },
          flip: {
            enabled: false
          }
        }}
        dateFormat="dd/MM/yyyy"
        autoComplete="off"
        customInput={<CustomInput isInvalid={invalid} />}
      />
      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </FormControl>
  )
}

export default DatePickerControl
