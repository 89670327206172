import React from 'react'
import { Tooltip, TooltipProps } from '@chakra-ui/react'

// [note]: "children" prop in this instance contains the Icon to be displayed

type TooltipWithIconProps = TooltipProps & {}

const TooltipWithIcon = (props: TooltipWithIconProps) => {
  const { children, label, placement, ...rest } = props
  return (
    <Tooltip hasArrow label={label} placement={placement} {...rest}>
      {children}
    </Tooltip>
  )
}

export default TooltipWithIcon
