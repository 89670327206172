// @ts-nocheck
import {
  Box,
  chakra,
  CheckboxGroup,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  omitThemingProps,
  Text,
  useCheckbox,
  useCheckboxGroupContext,
  VStack
} from '@chakra-ui/react'
import { callAll } from '@chakra-ui/utils'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import React, { FC, ReactNode } from 'react'
import { BaseProps } from './base-props'

export type MultiCheckboxImageControlProps = BaseProps & {
  checkboxProps?: CheckboxProps
  children?: ReactNode
  imageSet: { [key: string]: string }
  checkboxOptions?: { key: unknown; label: string; caption?: string }[]
  maxOneSelect?: boolean
  customSize?: { backgroundSize: string }
  customUncheckedOpacity?: string
}
export type ImageCheckboxProps = CheckboxProps & {
  customSize?: { backgroundSize: string }
  customUncheckedOpacity?: string
  imageSrc: string
}

// See https://github.com/chakra-ui/chakra-ui/discussions/3262

export const MultiCheckboxImageControl: FC<MultiCheckboxImageControlProps> = (
  props: MultiCheckboxImageControlProps
) => {
  const {
    name,
    label,
    children,
    checkboxOptions,
    imageSet,
    checkboxProps,
    maxOneSelect,
    customSize,
    customUncheckedOpacity,
    ...rest
  } = props
  const [field, meta] = useField(name)
  const formik = useFormikContext()
  const { onChange: _, ...restField } = field

  const onChange = (newArray: string[]) => {
    if (maxOneSelect) {
      formik.setFieldValue(name, [newArray[newArray.length - 1]])
    } else {
      formik.setFieldValue(name, newArray)
    }
  }

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <CheckboxGroup {...restField} onChange={onChange}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Grid gridTemplateColumns="1fr 1fr 1fr 1fr" gridGap="6">
          {checkboxOptions &&
            checkboxOptions.map((opt) => (
              <CustomCheckbox
                customSize={customSize}
                customUncheckedOpacity={customUncheckedOpacity}
                key={String(opt.key)}
                name={name}
                value={String(opt.key)}
                imageSrc={imageSet[String(opt.key)]}
              >
                {opt.label}
                {opt.caption && <Text fontSize="xs">{opt.caption}</Text>}
              </CustomCheckbox>
            ))}
          {children}
        </Grid>
        <ErrorMessage component={FormErrorMessage} name={name} />
      </CheckboxGroup>
    </FormControl>
  )
}

// Modified from
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/checkbox/src/checkbox.tsx

export const CustomCheckbox = (props: ImageCheckboxProps) => {
  const group = useCheckboxGroupContext()
  const ownProps = omitThemingProps(props)

  const {
    children,
    className,
    isChecked: isCheckedProp,
    isDisabled,
    onChange: onChangeProp,
    imageSrc,
    customSize,
    customUncheckedOpacity,
    ...rest
  } = ownProps

  let isChecked = isCheckedProp
  if (group?.value && ownProps.value) {
    isChecked = group.value.includes(ownProps.value)
  }

  let onChange = onChangeProp
  if (group?.onChange && ownProps.value) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const {
    state,
    getInputProps,
    getCheckboxProps,
    getLabelProps,
    getRootProps
  } = useCheckbox({
    ...rest,
    isDisabled,
    isChecked,
    onChange
  })

  const checkBox = getCheckboxProps()
  const input = getInputProps()

  return (
    <Label {...getRootProps()}>
      <VStack w="full">
        <input type="checkbox" {...input} />
        <Box
          transition="all 100ms ease-out"
          borderRadius="md"
          alignSelf="stretch"
          __css={{
            outlineColor: state.isFocused
              ? 'blue.500'
              : state.isChecked
              ? 'blue.800'
              : 'transparent',
            outlineWidth: '3',
            outlineStyle: 'solid',
            outlineOffset: 3
          }}
        >
          <Box
            {...checkBox}
            h="100px"
            transition="all 100ms ease-out"
            backgroundSize={customSize ? 'contain' : 'cover'}
            opacity={state.isChecked ? '' : customUncheckedOpacity || '40%'}
            borderRadius="md"
            bgImage={`url('${imageSrc}')`}
            bgRepeat="no-repeat"
            bgPosition="50% 50%"
          />
        </Box>
        <Text
          {...getLabelProps()}
          color={state.isChecked ? 'blue.800' : '#071937'}
          alignSelf="flex-start"
        >
          {children}
        </Text>
      </VStack>
    </Label>
  )
}

const Label = chakra('label', {
  baseStyle: {
    cursor: 'pointer',
    display: 'inline-flex',
    verticalAlign: 'top',
    position: 'relative',
    _disabled: {
      cursor: 'not-allowed'
    }
  }
})
