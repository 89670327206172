import * as React from 'react'

import { ErrorMessage, useField } from 'formik'
import {
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberInput,
  FormControl,
  FormLabel,
  NumberInputProps,
} from '@packages/ui-components'
import { formatPrice, fromLocalized } from '@packages/formatters/src/functions/currency'
import { BaseProps } from '@mylifetime-packages/form-components'


export type NumberInputControlProps = BaseProps & {
  numberInputProps?: NumberInputProps
  bgColor?: string
  onChange?: (value: string) => void
}

const CurrencyInputControl = (props: NumberInputControlProps) => {
  // This component cannot take float values such as 1.5. It must be a full integer
  const { name, placeholder, numberInputProps, bgColor = 'white' } = props

  const [field, { error, touched, value: fieldValue }, { setValue }] =
    useField(name)

  const format = (val: number | string | undefined) => {
    if (val === '' || val === undefined || Number.isNaN(val)) return ''
    if (val < 0) return ''
    if (typeof val == 'string') val = parseInt(val)
    return formatPrice(val)
  }
  const parse = (val: string) => {
    if (val.length) return fromLocalized(val)
    return ''
  }

  const $setFieldValue = (value: string) => setValue(parse(value))
  return (
    <FormControl
      id={props.name}
      isInvalid={error && touched ? true : false}
      isRequired={props.isRequired}
    >
      {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
      <NumberInput
        {...field}
        isInvalid={!!error && touched}
        value={format(fieldValue)}
        onChange={(value) => {
          $setFieldValue(value)
          if (props.onChange) {
            props.onChange(value)
          }
        }}
        min={0}
        step={1}
        size={'lg'}
        borderRadius='4px'
        pattern='\$?\d{0,3}(,\d{1,3})*(\.\d+)?'
        {...numberInputProps}
        bgColor={bgColor}
      >
        <NumberInputField borderRadius='md' placeholder={placeholder} />
        {/* We don't want to show the stepper if if it's a readonly field */}
        {!props.isReadOnly && (
          <NumberInputStepper>
            <NumberIncrementStepper fontSize="xx-small" />
            <NumberDecrementStepper fontSize="xx-small" />
          </NumberInputStepper>
        )}
      </NumberInput>

      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </FormControl>
  )
}
export { CurrencyInputControl }
