import React, { ReactNode } from 'react'
import { Form } from 'formik'
import { VStack } from '@packages/ui-components'

type Props = { children: React.ReactNode }

export const StyledForm: React.FC<Props> = ({ children }) => {
  return (
    <Form>
      <VStack spacing={4} align="stretch">
        {children}
      </VStack>
    </Form>
  )
}
export default StyledForm
