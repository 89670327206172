import React from 'react'
import { Checkbox as StyleCheckbox, CheckboxProps } from '@chakra-ui/react'

export const Checkbox = (props: CheckboxProps) => {
  return (
    <StyleCheckbox
      size='md'
      borderColor='grey.900'
      color='grey.400'
      spacing={4}
      {...props}
    >
      {props.children}
    </StyleCheckbox>
  )
}
