import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  VStack,
  Text,
  Center,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Tooltip
} from '@chakra-ui/react'
import { ErrorMessage, FieldArray, useField, useFormikContext } from 'formik'
import React, { FC, useEffect } from 'react'
import { BaseProps } from './base-props'
import DatePickerControl from './DatePickerControl'
import InputControl from './InputControl'
import {
  CalcQuestionEnum,
  CurrencyInputControl,
  Flex
} from '@packages/ui-components'
import { formatPrice } from '@packages/formatters/src/functions/currency'
import { AccordionTrigger } from '../../ui-components/src/AccordionSection'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { EducationCostsFormSlider } from '@packages/ui-components/src/EducationCostsFormSlider'

const HEALTH_IMAGE = `${process.env.NEXT_PUBLIC_IMGIX_URL}/calculator.jpg`

export type DependentsControlProps = BaseProps & {
  hasPartner: boolean
}

// Needs to be re-factored to use common constants under package
type Dependent = {
  firstName: string
  lastName?: string
  dateOfBirth?: Date
  relationshipType?: string
  gender?: string
  xplanItemId?: string
}

const Empty: Dependent = {
  firstName: '',
  lastName: '',
  gender: ''
}

interface TooltipCurrencyInputControlProps {
  name: string
  label: string
  costPerYear: number
}

const TooltipCurrencyInputControl: React.FC<
  TooltipCurrencyInputControlProps
> = ({ name, label, costPerYear }) => {
  return (
    <Box width="full">
      <Flex alignItems="center">
        <Text marginRight={2}>{label}</Text>
        <Tooltip
          label={`Based on ${formatPrice(costPerYear)} p/a`}
          placement="right"
        >
          <InfoOutlineIcon />
        </Tooltip>
      </Flex>
      <CurrencyInputControl name={name} />
    </Box>
  )
}

export const DependentsEducationCostControl: FC<DependentsControlProps> = ({
  hasPartner,
  ...props
}) => {
  const { name, label, children, ...rest } = props
  const [field] = useField(name)

  const array = field.value as Dependent[]

  return (
    <FormControl id={name} name={name} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <DependentsInner
            name={name}
            arrayHelpers={arrayHelpers}
            array={array}
            hasPartner={hasPartner}
          />
        )}
      />
      <ErrorMessage name={props.name}>
        {(errors) =>
          typeof errors === 'string' ? (
            <FormErrorMessage>{errors}</FormErrorMessage>
          ) : null
        }
      </ErrorMessage>
    </FormControl>
  )
}

interface EducationStage {
  name: string
  startAge: number
  endAge: number
  costPerYear: number
}

const educationStages: EducationStage[] = [
  { name: 'preschool', startAge: 3, endAge: 4, costPerYear: 1500000 },
  { name: 'primary', startAge: 5, endAge: 12, costPerYear: 500000 },
  { name: 'highSchool', startAge: 13, endAge: 17, costPerYear: 500000 },
  { name: 'tertiary', startAge: 18, endAge: 20, costPerYear: 2000000 }
]

function calculateRemainingCost(
  age: number
): { stage: string; remainingYears: number; totalCost: number }[] {
  if (age > 20) {
    return educationStages.map((stage) => ({
      stage: stage.name,
      remainingYears: 0,
      totalCost: 0
    }))
  }
  return educationStages
    .map((stage) => {
      const remainingYears = Math.max(
        0,
        stage.endAge - Math.max(stage.startAge, age) + 1
      )
      const totalCost = remainingYears * stage.costPerYear
      return {
        stage: stage.name,
        remainingYears,
        totalCost
      }
    })
    .filter((stage) => stage.remainingYears >= 0)
}

export const DependentsEdit = ({
  name,
  index,
  hideSliderPercentDisplay,
  hasPartner
}: {
  name: string
  showRelationshipType?: boolean
  showAddRemove?: boolean
  index: number
  submitCount?: number
  hideSliderPercentDisplay?: boolean
  hasPartner?: boolean
}) => {
  const { setFieldValue } = useFormikContext()

  const [{ value: preschool }] = useField(`${name}.${index}.preschool`)
  const [{ value: primary }] = useField(`${name}.${index}.primary`)
  const [{ value: highSchool }] = useField(`${name}.${index}.highSchool`)
  const [{ value: tertiary }] = useField(`${name}.${index}.tertiary`)

  const preschoolValue = parseInt(preschool, 10) || 0
  const primaryValue = parseInt(primary, 10) || 0
  const highSchoolValue = parseInt(highSchool, 10) || 0
  const tertiaryValue = parseInt(tertiary, 10) || 0

  const formatStageName = (stageName: string) => {
    switch (stageName.toLowerCase()) {
      case 'highschool':
        return 'High School'
      default:
        return stageName.charAt(0).toUpperCase() + stageName.slice(1)
    }
  }

  const totalMoney =
    preschoolValue + primaryValue + highSchoolValue + tertiaryValue

  const [{ value: dateOfBirth }] = useField(`${name}.${index}.dateOfBirth`)
  const questionMapping = {
    [CalcQuestionEnum.TRAUMA]:
      'Education funding in the event of you suffering a trauma.',
    [CalcQuestionEnum.TPD]:
      'Education funding in the event of you being permanently disabled.',
    [CalcQuestionEnum.LIFE]:
      'Education funding in the event of you dying prematurely.'
  }

  const questions = [
    CalcQuestionEnum.TRAUMA,
    CalcQuestionEnum.TPD,
    CalcQuestionEnum.LIFE
  ].map((type) => ({ key: type, value: questionMapping[type] }))

  function calculateAge(dateOfBirth: string): number {
    if (!dateOfBirth || isNaN(Date.parse(dateOfBirth))) {
      return 0
    }
    const currentDate = new Date()
    const birthDate = new Date(dateOfBirth)
    let age = currentDate.getFullYear() - birthDate.getFullYear()
    const monthDifference = currentDate.getMonth() - birthDate.getMonth()

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--
    }

    return age
  }

  // Updates education costs based on the dependent's age
  useEffect(() => {
    const age = calculateAge(dateOfBirth)
    const remainingCosts = calculateRemainingCost(age)
    remainingCosts.forEach((item) => {
      setFieldValue(`${name}.${index}.${item.stage}`, item.totalCost)
    })
  }, [dateOfBirth])

  return (
    <VStack key={index} bg="#E2E7EE" p="4" mb="4" borderRadius="lg">
      <HStack w="full" alignItems="flex-start">
        <InputControl
          name={`${name}.${index}.firstName`}
          label="First Name"
          width="full"
        />
        <InputControl
          name={`${name}.${index}.lastName`}
          label="Last Name"
          width="full"
        />
        <HStack
          w="full"
          sx={{
            '* .react-datepicker-wrapper': {
              width: '100%'
            },
            '* .react-datepicker-popper ': {
              zIndex: 9999
            }
          }}
          alignItems="flex-start"
        >
          <Box width="100%">
            <DatePickerControl
              name={`${name}.${index}.dateOfBirth`}
              label="Date of Birth"
              placeholder="13/04/1990"
            />
          </Box>
        </HStack>
        <Box width="full">
          <Center>
            <Text>Age</Text>
          </Center>
          <Box
            marginTop={2}
            paddingY={3}
            paddingX={'90px'}
            background="white"
            borderRadius="lg"
          >
            <Center>
              <Text>{calculateAge(dateOfBirth)}</Text>
            </Center>
          </Box>
        </Box>
      </HStack>
      <HStack w="full" alignItems="flex-start">
        {educationStages.map((stage) => (
          <TooltipCurrencyInputControl
            key={stage.name}
            name={`${name}.${index}.${stage.name}`}
            label={formatStageName(stage.name)}
            costPerYear={stage.costPerYear}
          />
        ))}
      </HStack>
      <HStack w="full" alignItems="flex-start" justifyContent="end">
        <Box paddingLeft={2} width="25%">
          <Center>
            <Text>Total</Text>
          </Center>
          <Box
            marginTop={2}
            paddingY={3}
            paddingX={16}
            background="white"
            borderRadius="lg"
          >
            <Center>
              <Text>{formatPrice(totalMoney)}</Text>
            </Center>
          </Box>
        </Box>
      </HStack>
      <Box paddingTop={5} width="100%">
        <EducationCostsFormSlider
          amount={totalMoney}
          questions={questions}
          formName={'education'}
          name={name}
          parentIndex={index}
          hideSliderPercentDisplay={hideSliderPercentDisplay}
          hasPartner={hasPartner}
        />
      </Box>
    </VStack>
  )
}

const DependentsInner = ({
  name,
  arrayHelpers,
  array,
  hasPartner
}: {
  name: string
  arrayHelpers: any
  array: Dependent[]
  hasPartner: boolean
}) => {
  const errorIndex = arrayHelpers?.form?.errors?.dependents
  return (
    <div>
      {array && array.length > 0
        ? array.map((_, index) => {
            return (
              <Accordion key={index} allowToggle defaultIndex={[0]}>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionTrigger
                        error={{
                          errorStatus:
                            errorIndex?.[index]?.lifeClientCapital ||
                            errorIndex?.[index]?.traumaClientCapital ||
                            errorIndex?.[index]?.tpdClientCapital ||
                            (hasPartner &&
                              (errorIndex?.[index]?.lifePartnerCapital ||
                                errorIndex?.[index]?.traumaPartnerCapital ||
                                errorIndex?.[index]?.tpdPartnerCapital))
                        }}
                      >
                        <AccordionButton>
                          <Box flex="1">
                            <Text textAlign="left" textTransform="none">
                              {array[index].firstName || 'Dependent'}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </AccordionTrigger>
                      <AccordionPanel mt={2}>
                        <DependentsEdit
                          name={name}
                          index={index}
                          // This hides the PercentageSlider when the accordion is closed
                          // to prevent it from moving to the left corner when closed.
                          hideSliderPercentDisplay={!isExpanded}
                          hasPartner={hasPartner}
                        />
                        <Button
                          variant="outline"
                          color="red"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remove {array[index].firstName || 'Dependent'}
                        </Button>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            )
          })
        : null}
      <Box my={8}>
        <Button
          size="sm"
          variant="ghost"
          onClick={() => {
            arrayHelpers.insert(array.length, Empty)
          }}
        >
          Add dependant
        </Button>
      </Box>
    </div>
  )
}
