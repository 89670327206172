import InputControl from '../InputControl'
import { InputProps, Text } from '@packages/ui-components'

import { MultiInputControl } from '../MultiInputControl'
import React from 'react'

type _react = typeof React
export const Email = (props: InputProps) => (
  <InputControl
    id="email"
    name="email"
    label="Email"
    placeholder="joe@blogs.com"
    isRequired
    type="email"
    autoCapitalize="none"
    {...props}
  />
)
export const FirstName = (props: InputProps) => (
  <InputControl
    id="firstName"
    name="firstName"
    label="First name"
    placeholder="Joe"
    isRequired
    {...props}
  />
)
export const LastName = (props: InputProps) => (
  <InputControl
    id="lastName"
    name="lastName"
    label="Last name"
    placeholder="Blogs"
    isRequired
    {...props}
  />
)
export const Phone = (props: InputProps) => (
  <InputControl
    {...props}
    id="phone"
    name={props.name ? props.name : 'phoneNumber'}
    label="Phone"
    placeholder="029 555 55 555"
  />
)
export const Address = (props: InputProps) => (
  <InputControl
    id="address"
    name="address"
    label="Address"
    placeholder="123 Alex street"
    {...props}
  />
)
export const ClientID = (props: InputProps) => (
  <InputControl
    id="currentXplanClientId"
    name="currentXplanClientId"
    label="Xplan ID"
    placeholder="123456"
    isRequired
    {...props}
  />
)

export const XplanClientAccounts = (props: InputProps) => (
  <MultiInputControl
    id="xplanClientAccounts"
    name="xplanClientAccounts"
    label="Xplan Client Account Ids"
    placeholder="123456"
    isRequired
    {...props}
  />
)

export const RegisterKey = (props: InputProps) => (
  <InputControl
    id="registerKey"
    name="registerKey"
    label="Register Key"
    placeholder="U3U7R4xnkqFEjK17KaiS1WFeMxXOLpV3"
    isRequired
    {...props}
  />
)
export const OTP = (props: InputProps) => (
  <InputControl
    id="code"
    name="code"
    label="Code"
    placeholder="123456"
    isRequired
    {...props}
  />
)

export default {
  Email,
  FirstName,
  LastName,
  Phone,
  Address,
  ClientID,
  XplanClientAccounts,
  RegisterKey,
  OTP
}
